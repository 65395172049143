import React, { ChangeEventHandler, useState, useRef } from 'react'
import {
  Button,
  Checkbox,
  FieldLabel,
  FieldValue,
  Link,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
  Section,
  Text,
  TextInput,
} from '@lightspeed/design-system-react'
import { gql, useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha'
import { StyledReCAPTCHA } from '../util/recaptcha'
import { NewPasswordField } from '../components/NewPasswordField/NewPasswordField'
import { EmailInput } from '../components/EmailInput/EmailInput'
import { onErrorToastNotifications } from '../util/client'

export interface SignupMutationInput {
  name: string
  email: string
  password: string
  wantsNewsletter: boolean
  recaptchaToken: string
}

const SignupMutation = gql`
  mutation SignupMutation($input: SignupInput!) {
    signup(input: $input) {
      email
    }
  }
`

export const RegisterPage = () => {
  const navigate = useNavigate()

  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [emailValid, setEmailValid] = useState<boolean>(true)
  const [password, setPassword] = useState<string>('')
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('')
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false)
  const [wantsNewsletter, setWantsNewsletter] = useState<boolean>(false)
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>()
  const [signupSuccessful, setSignupSuccessful] = useState<boolean>(false)
  const [strongPassword, setStrongPassword] = useState<boolean>(true)

  const recaptchaRef = useRef<ReCAPTCHA>(null)

  const onEmailChange: ChangeEventHandler<HTMLInputElement> = event => {
    setEmail(event.target.value)
    setEmailValid(!!event.target.value && event.target.checkValidity())
  }

  const passwordsMatch = password === passwordConfirmation

  const valid =
    name &&
    email &&
    emailValid &&
    password &&
    termsAccepted &&
    passwordsMatch &&
    recaptchaToken &&
    strongPassword

  const [signup, { loading }] = useMutation<
    { signup: { email: string } },
    { input: SignupMutationInput }
  >(SignupMutation, { onError: onErrorToastNotifications })

  const onSubmit = () => {
    signup({
      variables: {
        input: {
          name,
          email,
          password,
          wantsNewsletter,
          recaptchaToken: recaptchaToken || '',
        },
      },
    })
      .then(({ errors }) => {
        if (errors) return
        setSignupSuccessful(true)
      })
      .finally(() => {
        if (recaptchaRef.current?.reset) {
          recaptchaRef.current?.reset()
        }
      })
  }

  if (signupSuccessful) {
    return (
      <Modal focusTrapActive={false}>
        <ModalHeader>An email has been sent to your email address</ModalHeader>
        <ModalContent>
          Please follow the directions in the email to activate your account.
        </ModalContent>
        <ModalActions>
          <Button onClick={() => navigate('/login')}>OK</Button>
        </ModalActions>
      </Modal>
    )
  }

  return (
    <Modal focusTrapActive={false}>
      <ModalHeader>Create account</ModalHeader>
      <ModalContent>
        <Text tag="p">
          Join now and become part of the Lightspeed Retail Developer Community.
        </Text>
        <Section>
          <FieldLabel label="Name">
            <FieldValue>
              <TextInput
                value={name}
                onChange={e => setName(e.target.value)}
                disabled={loading}
              />
            </FieldValue>
          </FieldLabel>
        </Section>
        <Section>
          <FieldLabel label="Email">
            <FieldValue>
              <EmailInput
                value={email}
                onChange={onEmailChange}
                disabled={loading}
                hasError={!emailValid}
              />
            </FieldValue>
          </FieldLabel>
        </Section>
        <Section>
          <FieldLabel label="Password">
            <FieldValue>
              <NewPasswordField
                value={password}
                onChange={e => setPassword(e.target.value)}
                disabled={loading}
                userInputs={[...name.split(' '), email]}
                onScore={(_, strong) => setStrongPassword(strong)}
                hasError={!!password && !strongPassword}
              />
            </FieldValue>
          </FieldLabel>
        </Section>
        <Section>
          <FieldLabel label="Confirm password">
            <FieldValue>
              <TextInput
                type="password"
                value={passwordConfirmation}
                onChange={e => setPasswordConfirmation(e.target.value)}
                hasError={!passwordsMatch}
                disabled={loading}
              />
            </FieldValue>
          </FieldLabel>
        </Section>
        <Section>
          <Checkbox
            label="I'd like to receive Lightspeed Retail's Developer Update newsletter"
            checked={wantsNewsletter}
            onChange={e => setWantsNewsletter(e.target.checked)}
            disabled={loading}
          />
          <div className="vd-flex">
            <Checkbox
              label="I accept the"
              checked={termsAccepted}
              onChange={e => setTermsAccepted(e.target.checked)}
              disabled={loading}
            />
            <Link
              href="https://developers.lightspeedhq.com/terms"
              rel="noreferrer noopener"
              target="_blank"
              style={{ margin: 'auto 3px' }}
            >
              Terms and Conditions
            </Link>
          </div>
        </Section>
        <Section>
          <StyledReCAPTCHA onChange={setRecaptchaToken} ref={recaptchaRef} />
        </Section>
      </ModalContent>
      <ModalActions>
        <Button disabled={!valid} loading={loading} onClick={onSubmit}>
          Create Account
        </Button>
      </ModalActions>
    </Modal>
  )
}
