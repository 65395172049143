import { gql, useQuery } from '@apollo/client'
import {
  Link,
  Popover,
  PopoverList,
  PopoverListItem,
  WithPopover,
} from '@lightspeed/design-system-react'
import React, { useState } from 'react'
import { Link as RouterLink, Route, Routes } from 'react-router-dom'
import './NavBar.scss'

const NavBarQuery = gql`
  query NavBarQuery {
    developer {
      id
      name
    }
  }
`

interface Developer {
  name: string
}

const DeveloperMenu = () => {
  const [showMenu, setShowMenu] = useState<boolean>(false)
  const { data } = useQuery<{ developer: Developer }>(NavBarQuery)

  return (
    <>
      <WithPopover direction="below">
        <div className="vd-nav-item dev-menu">
          <div
            className="vd-nav-item-action"
            onClick={() => setShowMenu(!showMenu)}
          >
            <div className="vd-nav-item-label">{data?.developer.name}</div>
          </div>
        </div>
        {showMenu && (
          <Popover
            onClose={() => setShowMenu(false)}
            className="vd-popover--with-list"
          >
            <PopoverList>
              <PopoverListItem
                index={2}
                onClick={() => document.location.assign('/logout')}
              >
                Logout
              </PopoverListItem>
            </PopoverList>
          </Popover>
        )}
      </WithPopover>
    </>
  )
}

export const NavBar = () => {
  return (
    <div className="nv-topnav vd-topbar">
      <div className="nv-topnav-header vd-nav-item">
        <RouterLink className="vd-logo-container" to="/">
          <img
            alt="Lightspeed"
            src="//vendfrontendassets.freetls.fastly.net/images/logos/lightspeed-logo-white-v3.svg"
            className="vd-logo"
            width="120px"
          />
        </RouterLink>
      </div>
      <div className="nv-topnav-content">
        <div className="vd-nav-item">
          <Link
            href="https://x-series-api.lightspeedhq.com/"
            isExternal
            className="vd-nav-item-action"
          >
            Documentation
          </Link>
        </div>
        <Routes>
          <Route path="/login" />
          <Route path="/forgot-password" />
          <Route path="/reset-password/:token" />
          <Route path="/register" />
          <Route path="/" />
          <Route path="*" element={<DeveloperMenu />} />
        </Routes>
      </div>
    </div>
  )
}
