import { Button, Header, Icon, Modal, ModalActions, ModalContent, ModalHeader, Text, ToastNotifications } from '@lightspeed/design-system-react'
import React, { FunctionComponent } from 'react'

interface ClientSecretModalProps {
  secret: string
  id?: string
  onClose: () => void
}

export const ClientSecretModal: FunctionComponent<ClientSecretModalProps> = ({ secret, id, onClose }) => {
  const copyToClipboard = (text: string, message: string) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        ToastNotifications.success(message)
      }).catch(() => {
        ToastNotifications.negative('Failed to copy to clipboard.')
      })
  }
  return (
    <Modal dismissable onClose={onClose}>
      <ModalHeader>{id ? 'Copy your Client ID and Secret.' : 'Copy your Client Secret.'}</ModalHeader>
      <ModalContent>
        <Text className="vd-mb3" tag="p">Make sure to copy your {id ? 'Client ID and ' : ''}Client Secret. You won't be able to see the secret again.</Text>
        {id && (
          <>
            <Header level={'3'} type={'sub-heading'}>Client ID</Header>
            <Text tag="p">{id} <Button modifier="icon" className="vd-ml2" type="button" onClick={() => copyToClipboard(id, 'Client ID copied to clipboard')}><Icon className="vd-mr2" icon="clipboard" /> Copy</Button></Text>  
            <Header level={'3'} type={'sub-heading'}>Client Secret</Header>
          </>
        )}
        <Text tag="p">{secret} <Button modifier="icon" className="vd-ml2" type="button" onClick={() => copyToClipboard(secret, 'Client Secret copied to clipboard')}><Icon className="vd-mr2" icon="clipboard" /> Copy</Button></Text>
      </ModalContent>
      <ModalActions>
        <Button type="button" onClick={onClose}>Done</Button>
      </ModalActions>
    </Modal>
  )
}
