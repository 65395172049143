import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
  ApolloError,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { ToastNotifications } from '@lightspeed/design-system-react'
import { fetchWithCSRF } from './csrf'

interface ErrorWithStatusCode {
  statusCode: number
}

export const client = new ApolloClient({
  link: from([
    onError(({ networkError, graphQLErrors }) => {
      let authError =
        Boolean(
          graphQLErrors?.find(e => e.extensions?.code === 'UNAUTHORIZED')
        ) ||
        (networkError &&
          (networkError as ErrorWithStatusCode).statusCode === 401)
      if (authError) {
        document.location.assign(
          '/login?return_to=' +
            encodeURIComponent(
              document.location.pathname + document.location.search
            )
        )
      }
    }),
    createHttpLink({
      uri: '/graph/query',
      fetch: fetchWithCSRF,
    }),
  ]),
  cache: new InMemoryCache(),
})

interface BaseGraphQLErrorExtensions {
  code: any
  [attributeName: string]: unknown
}

export interface UnauthorizedError extends BaseGraphQLErrorExtensions {
  code: 'UNAUTHORIZED'
}

export interface InvalidError extends BaseGraphQLErrorExtensions {
  code: 'INVALID'
  field: string
  message: string
}

export interface ConflictError extends BaseGraphQLErrorExtensions {
  code: 'CONFLICT'
  field: string
  message: string
}

export interface NotFoundError extends BaseGraphQLErrorExtensions {
  code: 'NOT_FOUND'
  message: string
}

export interface EmailError extends BaseGraphQLErrorExtensions {
  code: 'EMAIL'
  message: string
}

export interface ExpiredError extends BaseGraphQLErrorExtensions {
  code: 'EXPIRED'
  message: string
}

export type GraphQLErrorExtensions =
  | UnauthorizedError
  | InvalidError
  | ConflictError
  | NotFoundError
  | EmailError
  | ExpiredError
  | {
      code: undefined
      [attributeName: string]: unknown
    }

export const onErrorToastNotifications = (e: ApolloError) => {
  e.graphQLErrors.forEach(gqlErr => {
    if (!gqlErr.extensions) {
      ToastNotifications.negative('Something went wrong. Maybe try again.')
      return
    }

    const ext = gqlErr.extensions as GraphQLErrorExtensions

    switch (ext.code) {
      case 'UNAUTHORIZED':
        return
      case 'INVALID':
      case 'CONFLICT':
      case 'NOT_FOUND':
      case 'EMAIL':
      case 'EXPIRED':
        ToastNotifications.negative(ext.message)
        break
      default:
        ToastNotifications.negative('Something went wrong. Maybe try again.')
        break
    }
  })
}
