import {
  Button,
  FieldLabel,
  FieldValue,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
  Section,
  TextInput,
  ToastNotifications,
} from '@lightspeed/design-system-react'
import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { useNavigate, useParams } from 'react-router-dom'
import { NewPasswordField } from '../components/NewPasswordField/NewPasswordField'

export interface ResetPasswordInput {
  password: string
  passwordResetToken: string
}

const RequestPasswordResetMutation = gql`
  mutation ResetPasswordMutation($input: ResetPasswordInput!) {
    resetPassword(input: $input)
  }
`

export const PasswordResetPage = () => {
  const { token } = useParams()
  const navigate = useNavigate()
  const [password, setPassword] = useState<string>('')
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('')
  const [strongPassword, setStrongPassword] = useState<boolean>(true)
  const [success, setSuccess] = useState<boolean>(false)

  const passwordsMatch = password === passwordConfirmation
  const valid = password && passwordsMatch && token && strongPassword

  const [requestPasswordReset, { loading }] = useMutation<
    { resetPassword: boolean | null },
    { input: ResetPasswordInput }
  >(RequestPasswordResetMutation)

  const onSubmit = () => {
    requestPasswordReset({
      variables: {
        input: {
          password,
          passwordResetToken: token || '',
        },
      },
    })
      .then(({ data }) => {
        if (data?.resetPassword) {
          setSuccess(true)
        } else {
          ToastNotifications.negative('We failed to update your password.')
        }
      })
      .catch(() => {
        ToastNotifications.negative('We failed to update your password.')
      })
  }

  if (success) {
    return (
      <Modal focusTrapActive={false}>
        <ModalHeader>We've updated your password</ModalHeader>
        <ModalContent>Please use your new password to log in.</ModalContent>
        <ModalActions>
          <Button onClick={() => navigate('/login')}>OK</Button>
        </ModalActions>
      </Modal>
    )
  }

  return (
    <Modal focusTrapActive={false}>
      <ModalHeader>Reset your password</ModalHeader>
      <ModalContent>
        <Section>Enter your new password.</Section>
        <Section>
          <FieldLabel label="Password">
            <FieldValue>
              <NewPasswordField
                type="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                disabled={loading}
                onScore={(_, strong) => setStrongPassword(strong)}
                hasError={!!password && !strongPassword}
              />
            </FieldValue>
          </FieldLabel>
        </Section>
        <Section>
          <FieldLabel label="Confirm password">
            <FieldValue>
              <TextInput
                type="password"
                value={passwordConfirmation}
                onChange={e => setPasswordConfirmation(e.target.value)}
                hasError={!passwordsMatch}
                disabled={loading}
              />
            </FieldValue>
          </FieldLabel>
        </Section>
      </ModalContent>
      <ModalActions>
        <Button disabled={!valid} loading={loading} onClick={onSubmit}>
          Update Password
        </Button>
      </ModalActions>
    </Modal>
  )
}
