import React from 'react'
import { AppRouter } from './pages/AppRouter'
import '@lightspeed/design-system-css'
import { NavBar } from './components/NavBar/NavBar'
import { ApolloProvider } from '@apollo/client'
import { client } from './util/client'
import { ToastNotifications } from '@lightspeed/design-system-react'
import { SideNav } from './components/SideNav/SideNav'
import { BrowserRouter } from 'react-router-dom'

function App() {
  return (
    <>
      <ToastNotifications />
      <ApolloProvider client={client}>
        <BrowserRouter>
          <div className="vd-body">
            <NavBar />
            <SideNav />
            <div className="vd-main-content-container">
              <div className="vd-main-content-inner-container">
                <div className="vd-main-content">
                  <AppRouter />
                </div>
              </div>
            </div>
          </div>
        </BrowserRouter>
      </ApolloProvider>
    </>
  )
}

export default App
