import { TextInputProps } from '@lightspeed/design-system-react/dist/components/textInput/TextInput'
import { TextInput } from '@lightspeed/design-system-react'
import React from 'react'

export const EmailInput = ({
  type = 'email',
  pattern = "[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)+",
  ...attributes
}: TextInputProps) => {
  return <TextInput type={type} pattern={pattern} {...attributes} />
}
