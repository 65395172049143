import {
  Button,
  FieldLabel,
  FieldValue,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
  Section,
  TextInput,
  ToastNotifications,
} from '@lightspeed/design-system-react'
import React, { useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { StyledReCAPTCHA } from '../util/recaptcha'
import { gql, useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

export interface RequestPasswordResetInput {
  email: string
  recaptchaToken: string
}

const RequestPasswordResetMutation = gql`
  mutation RequestPasswordResetMutation($input: RequestPasswordResetInput!) {
    requestPasswordReset(input: $input)
  }
`

export const RequestPasswordResetPage = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState<string>('')
  const [success, setSuccess] = useState<boolean>(false)
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>()

  const [requestPasswordReset, { loading }] = useMutation<
    { requestPasswordReset: boolean | null },
    { input: RequestPasswordResetInput }
  >(RequestPasswordResetMutation)
  const recaptchaRef = useRef<ReCAPTCHA>(null)

  const onSubmit = () => {
    requestPasswordReset({
      variables: {
        input: {
          email,
          recaptchaToken: recaptchaToken || '',
        },
      },
    })
      .then(({ data }) => {
        if (data?.requestPasswordReset) {
          setSuccess(true)
        } else {
          ToastNotifications.negative(`We failed to send an email to ${email}.`)
        }
      })
      .catch(() => {
        ToastNotifications.negative(`We failed to send an email to ${email}.`)
      })
      .finally(() => {
        if (recaptchaRef.current?.reset) {
          recaptchaRef.current?.reset()
        }
      })
  }

  if (success) {
    return (
      <Modal focusTrapActive={false}>
        <ModalHeader>An email has been sent to {email}</ModalHeader>
        <ModalContent>
          Please follow the directions in the email to reset your password.
        </ModalContent>
        <ModalActions>
          <Button onClick={() => navigate('/login')}>OK</Button>
        </ModalActions>
      </Modal>
    )
  }

  return (
    <Modal focusTrapActive={false}>
      <ModalHeader>Forgotten your password?</ModalHeader>
      <ModalContent>
        <Section>
          Enter the email address used for your account below and we'll email
          you instuctions on how to reset it.
        </Section>
        <Section>
          <FieldLabel label="Email">
            <FieldValue>
              <TextInput
                type="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                disabled={loading}
              />
            </FieldValue>
          </FieldLabel>
        </Section>
        <Section>
          <StyledReCAPTCHA onChange={setRecaptchaToken} ref={recaptchaRef} />
        </Section>
      </ModalContent>
      <ModalActions>
        <Button
          onClick={onSubmit}
          disabled={!email || !recaptchaToken}
          loading={loading}
        >
          Send Instructions
        </Button>
      </ModalActions>
    </Modal>
  )
}
