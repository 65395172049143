import React from 'react'
import {
  ButtonLink,
  Header,
  Section,
  Text,
} from '@lightspeed/design-system-react'
import { Link } from 'react-router-dom'

export const HomePage = () => (
  <>
    <Section className="vd-align-center">
      <Header level="1" type="grand" className="vd-mb8 vd-mt10">
        Register and access Lightspeed Retail's API.
      </Header>
      <Text tag="p" type="intro" className="vd-mb10">
        Become part of the Lightspeed Retail developer community.
      </Text>
      <Text tag="p" type="intro" className="vd-mb10">
        Access the API documentation, generate your OAuth key, and start
        building awesome stuff.
      </Text>
      <div className="vd-btn-group vd-mb10">
        <Link className="vd-btn vd-btn--jumbo vd-btn--go" to="/register">
          Register as a developer
        </Link>
        <Link
          className="vd-btn vd-btn--jumbo vd-btn--supplementary"
          to="/login"
        >
          Sign in
        </Link>
      </div>
      <Text tag="p">
        Not sure? Learn about developing with Lightspeed Retail on our{' '}
        <a href="https://www.lightspeedhq.com/partners/developers/">Developer page</a>.
      </Text>
    </Section>
    <hr className="vd-hr" />
    <Section className="vd-align-center">
      <Header level="2" type="heading" className="vd-mb5">
        Any questions?
      </Header>
      <Text tag="p" className="vd-mb5">
        <ButtonLink variant="go" jumbo href="mailto:x-series.api@lightspeedhq.com">
          Contact us
        </ButtonLink>
      </Text>
      <Text tag="p">We can help at every step.</Text>
    </Section>
  </>
)
