import { getCookie } from "./cookies"

export const fetchWithCSRF: WindowOrWorkerGlobalScope['fetch'] = (
  input,
  init
) => {
  init = init || {}
  init.credentials = 'include'

  const token = getCookie('XSRF-TOKEN')

  if (token) {
    if (init.headers instanceof Headers) {
      init.headers.set('X-Xsrf-Token', token)
    } else {
      init.headers = init.headers || {}
      init.headers = Object.assign(init.headers || {}, {
        'X-Xsrf-Token': token,
      })
    }
  }

  return fetch(input, init)
}
