import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { ApplicationEditPage } from './ApplicationEditPage'
import { ApplicationsPage } from './ApplicationsPage'
import { HomePage } from './HomePage'
import { LoginPage } from './LoginPage'
import { PasswordResetPage } from './PasswordResetPage'
import { AccountPage } from './AccountPage'
import { RegisterPage } from './RegisterPage'
import { RequestPasswordResetPage } from './RequestPasswordResetPage'
import { NotFoundPage } from '@vend/react-utilities'

export const AppRouter = () => (
  <Routes>
    <Route path="/login" element={<LoginPage />} />
    <Route path="/forgot-password" element={<RequestPasswordResetPage />} />
    <Route path="/reset-password/:token" element={<PasswordResetPage />} />
    <Route path="/register" element={<RegisterPage />} />
    <Route path="/applications/add" element={<ApplicationEditPage />} />
    <Route path="/applications/:id/edit" element={<ApplicationEditPage />} />
    <Route path="/applications" element={<ApplicationsPage />} />
    <Route path="/account" element={<AccountPage />} />
    <Route path="/" element={<HomePage />} />

    <Route
      path="/developer/sign-up"
      element={<Navigate to="/register" replace />}
    />
    <Route
      path="/developer/sign-in"
      element={<Navigate to="/login" replace />}
    />
    <Route
      path="/developer/applications"
      element={<Navigate to="/applications" replace />}
    />
    <Route
      path="/developer/applications/create"
      element={<Navigate to="/applications/add" replace />}
    />
    <Route
      path="/developer/applications/*"
      element={<Navigate to="/applications" replace />}
    />
    <Route
      path="/developer/profile"
      element={<Navigate to="/account" replace />}
    />

    <Route path="*" element={<NotFoundPage />} />
  </Routes>
)
