import { gql, useMutation, useQuery } from '@apollo/client'
import {
  ActionBar,
  ActionBarActions,
  ActionBarMessage,
  Button,
  FieldLabel,
  Link,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
  PageHeader,
  SettingsSection,
  StickySection,
  Text,
  TextInput,
  ToastNotifications,
} from '@lightspeed/design-system-react'
import React, { ChangeEventHandler, FormEvent, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ApplicationsTableQuery } from '../components/ApplicationsTable/ApplicationsTable'
import { ClientSecretModal } from '../components/ClientSecretModal/ClientSecretModal'
import { onErrorToastNotifications } from '../util/client'

export const ApplicationEditFragment = gql`
  fragment ApplicationEditFragment on Application {
    id
    name
    redirectURI
  }
`

const ApplicationEditQuery = gql`
  ${ApplicationEditFragment}

  query ApplicationEditQuery($id: ID!) {
    application(id: $id) {
      ...ApplicationEditFragment
    }
  }
`

const ApplicationEditCreateMutation = gql`
  ${ApplicationEditFragment}

  mutation ApplicationEditCreateMutation($input: CreateApplicationInput!) {
    createApplication(input: $input) {
      application {
        ...ApplicationEditFragment
      }
      secret
    }
  }
`

const ApplicationEditUpdateMutation = gql`
  ${ApplicationEditFragment}

  mutation ApplicationEditUpdateMutation($input: UpdateApplicationInput!) {
    updateApplication(input: $input) {
      ...ApplicationEditFragment
    }
  }
`

const ApplicationGenerateSecretMutation = gql`
  ${ApplicationEditFragment}

  mutation ApplicationGenerateSecretMutation(
    $input: GenerateNewApplicationSecretInput!
  ) {
    generateNewApplicationSecret(input: $input) {
      application {
        ...ApplicationEditFragment
      }
      secret
    }
  }
`

interface Application {
  id: string
  name: string
  redirectURI: string
}

interface CreateApplicationInput {
  name: string
  redirectURI: string
}

interface UpdateApplicationInput {
  id: string
  name?: string
  redirectURI?: string
}

interface GenerateNewApplicationSecretInput {
  id: string
}

export const ApplicationEditPage = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const { data, loading } = useQuery<
    { application: Application | null },
    { id: string }
  >(ApplicationEditQuery, { variables: { id: id || '' }, skip: !id })

  const [createApplication, { loading: creating }] = useMutation<
    {
      createApplication: {
        application: Application
        secret: string
      }
    },
    { input: CreateApplicationInput }
  >(ApplicationEditCreateMutation, {
    onError: onErrorToastNotifications,
    refetchQueries: [{ query: ApplicationsTableQuery }],
  })

  const [updateApplication, { loading: updating }] = useMutation<
    { updateApplication: Application },
    { input: UpdateApplicationInput }
  >(ApplicationEditUpdateMutation, {
    onError: onErrorToastNotifications,
  })

  const [generateSecret, { loading: generatingSecret }] = useMutation<
    {
      generateNewApplicationSecret: {
        application: Application
        secret: string
      }
    },
    { input: GenerateNewApplicationSecretInput }
  >(ApplicationGenerateSecretMutation, {
    onError: onErrorToastNotifications,
  })

  const [newClientID, setNewClientID] = useState<string>()
  const [newClientSecret, setNewClientSecret] = useState<string>()
  const [name, setName] = useState<string>()
  const [redirectURI, setRedirectURI] = useState<string>()
  const [redirectURIValid, setRedirectURIValid] = useState<boolean>(true)
  const [showConfirmNewSecret, setShowConfirmNewSecret] =
    useState<boolean>(false)

  const onRedirectURIChange: ChangeEventHandler<HTMLInputElement> = event => {
    setRedirectURI(event.target.value)
    setRedirectURIValid(!!event.target.value && event.target.checkValidity())
  }

  const valid =
    (name ?? data?.application?.name) &&
    (redirectURI ?? data?.application?.redirectURI) &&
    redirectURIValid

  const copyToClipboard = (text: string, message: string) =>
    navigator.clipboard
      .writeText(text)
      .then(() => {
        ToastNotifications.success(message)
      })
      .catch(() => {
        ToastNotifications.negative(
          "Sorry, we couldn't copy that to your clipboard"
        )
      })

  const closeSecretModal = () => {
    if (id) {
      setNewClientSecret(undefined)
    } else {
      navigate('/applications')
    }
  }

  const handleCreate = () => {
    return createApplication({
      variables: {
        input: {
          name: name || '',
          redirectURI: redirectURI || '',
        },
      },
    }).then(({ data }) => {
      if (data) {
        const {
          createApplication: { application, secret },
        } = data
        ToastNotifications.success(
          `Application '${application.name}' has been created.`
        )
        setNewClientID(application.id)
        setNewClientSecret(secret)
      }
    })
  }

  const handleUpdate = () => {
    return updateApplication({
      variables: {
        input: {
          id: id || '',
          name,
          redirectURI,
        },
      },
    }).then(({ data }) => {
      if (data) {
        const { updateApplication: application } = data
        ToastNotifications.success(
          `Application '${application.name}' has been updated.`
        )
        navigate('/applications')
      }
    })
  }

  const handleGenerateSecret = () => {
    if (!id) return
    return generateSecret({
      variables: {
        input: {
          id: id,
        },
      },
    }).then(({ data }) => {
      if (data) {
        const {
          generateNewApplicationSecret: { application, secret },
        } = data
        ToastNotifications.success(`A new Client Secret was generated.`)
        setNewClientID(application.id)
        setNewClientSecret(secret)
        setShowConfirmNewSecret(false)
      }
    })
  }

  const handleSave = (e: FormEvent) => {
    e.preventDefault()

    if (id) {
      return handleUpdate()
    } else {
      return handleCreate()
    }
  }

  return (
    <form onSubmit={handleSave}>
      <PageHeader backUrl="/applications">
        {id ? 'Edit' : 'Add'} Application
      </PageHeader>
      <StickySection>
        <ActionBar variant="page">
          <ActionBarMessage>
            {id ? 'Update your application details.' : 'Add your application.'}
          </ActionBarMessage>
          <ActionBarActions>
            <Button
              type="submit"
              loading={loading || creating || updating || generatingSecret}
              disabled={!valid}
            >
              Save Application
            </Button>
          </ActionBarActions>
        </ActionBar>
      </StickySection>
      <SettingsSection header="Application Name">
        <SettingsSection.About>
          The application name will be visible to users. You can edit this
          later.
        </SettingsSection.About>
        <SettingsSection.Content>
          <FieldLabel label="Application Name">
            <TextInput
              placeholder="Enter name"
              value={name ?? data?.application?.name}
              onChange={e => setName(e.target.value)}
              hasError={name === ''}
            />
          </FieldLabel>
        </SettingsSection.Content>
      </SettingsSection>
      <SettingsSection header="Redirect URL">
        <SettingsSection.About>
          The user will be redirected to this URL after connecting to the URL.{' '}
          <Link href="https://x-series-api.lightspeedhq.com/docs/authorization" isExternal>
            Learn more about authentication
          </Link>
          .
        </SettingsSection.About>
        <SettingsSection.Content>
          <FieldLabel label="Redirect URL">
            <TextInput
              type="url"
              pattern="https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)"
              placeholder="Enter URL"
              value={redirectURI ?? data?.application?.redirectURI}
              onChange={onRedirectURIChange}
              hasError={!redirectURIValid}
            />
          </FieldLabel>
        </SettingsSection.Content>
      </SettingsSection>
      {id && (
        <>
          <SettingsSection header="Client ID">
            <SettingsSection.About>
              This is the unique identifier of your application.
            </SettingsSection.About>
            <SettingsSection.Content>
              <Text tag="p" type="label" className="vd-mb3">
                Client ID
              </Text>
              <Text tag="p" className="vd-mb3">
                {id}
              </Text>
              <div>
                <Button
                  type="button"
                  onClick={() =>
                    copyToClipboard(id, 'Client ID copied to your clipboard.')
                  }
                >
                  Copy
                </Button>
              </div>
            </SettingsSection.Content>
          </SettingsSection>

          <SettingsSection header="Client Secret">
            <SettingsSection.About>
              You can not see your existing client secret, but you can generate
              a new one.
            </SettingsSection.About>
            <SettingsSection.Content>
              <Button
                onClick={() => setShowConfirmNewSecret(true)}
                loading={generatingSecret}
                disabled={loading || creating || updating}
              >
                Generate New Secret
              </Button>
            </SettingsSection.Content>
          </SettingsSection>
        </>
      )}
      {newClientSecret && (
        <ClientSecretModal
          onClose={closeSecretModal}
          secret={newClientSecret}
          id={newClientID}
        />
      )}
      {showConfirmNewSecret && (
        <Modal onClose={() => setShowConfirmNewSecret(false)}>
          <ModalHeader>Generating a new secret</ModalHeader>
          <ModalContent>
            <p>
              When generating a new secret for your application, the old secret
              will immediately stop working.
            </p>
            <p>Are you sure?</p>
          </ModalContent>
          <ModalActions className="vd-btn-group">
            <Button
              loading={generatingSecret}
              disabled={loading || creating || updating}
              variant="supplementary"
              onClick={() => setShowConfirmNewSecret(false)}
            >
              Cancel
            </Button>
            <Button
              loading={generatingSecret}
              disabled={loading || creating || updating}
              variant="go"
              onClick={handleGenerateSecret}
            >
              Yes
            </Button>
          </ModalActions>
        </Modal>
      )}
    </form>
  )
}
