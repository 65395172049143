import React, { useEffect, useState } from 'react'
import { zxcvbn, zxcvbnOptions, ZxcvbnResult } from '@zxcvbn-ts/core'
import { ErrorMessage, TextInput } from '@lightspeed/design-system-react'
import { TextInputProps } from '@lightspeed/design-system-react/dist/components/textInput/TextInput'

// These imports are large so we lazy load them.
// https://zxcvbn-ts.github.io/zxcvbn/guide/lazy-loading/#webpack
const loadOptions = async () => {
  const zxcvbnCommonPackage = await import(
    /* webpackChunkName: "zxcvbnCommonPackage" */ '@zxcvbn-ts/language-common'
  )
  const zxcvbnEnPackage = await import(
    /* webpackChunkName: "zxcvbnEnPackage" */ '@zxcvbn-ts/language-en'
  )

  return {
    dictionary: {
      ...zxcvbnCommonPackage.default.dictionary,
      ...zxcvbnEnPackage.default.dictionary,
    },
    graphs: zxcvbnCommonPackage.default.adjacencyGraphs,
    translations: zxcvbnEnPackage.default.translations,
  }
}

export interface NewPasswordFieldProps extends TextInputProps {
  minScore?: number
  onScore?: (score: number, pass: boolean) => void
  userInputs?: string[]
}

export const NewPasswordField = ({
  value,
  type = 'password',
  disabled,
  minScore = 3,
  onScore,
  userInputs,
  ...attributes
}: NewPasswordFieldProps) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [result, setResult] = useState<ZxcvbnResult>()

  useEffect(() => {
    // lazy load the data.
    loadOptions().then(options => {
      zxcvbnOptions.setOptions(options)
      setLoading(false)
    })
  })

  useEffect(() => {
    const r = zxcvbn(String(value || ''), userInputs)
    setResult(r)
    if (onScore) onScore(r.score, r.score >= minScore)
  }, [value, userInputs, onScore, minScore])

  let message = ''
  if (result && result.score < minScore) {
    message =
      result.feedback.warning || result.feedback.suggestions.join('. ') || ''
  }

  return (
    <>
      <TextInput
        value={value}
        disabled={disabled || loading}
        type={type}
        {...attributes}
      />
      {value && message && <ErrorMessage>{message}</ErrorMessage>}
    </>
  )
}
