import {
  Button,
  FieldLabel,
  FieldValue,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
  Section,
  TextInput,
  ToastNotifications,
} from '@lightspeed/design-system-react'
import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { NewPasswordField } from '../NewPasswordField/NewPasswordField'

export interface ChangePasswordInput {
  password: string
  newPassword: string
}

const ChangePasswordMutation = gql`
  mutation ChangePasswordMutation($input: ChangePasswordInput!) {
    changePassword(input: $input) {
      id
    }
  }
`

interface Props {
  onClose: () => void
}

export const ChangePasswordModal = ({ onClose }: Props) => {
  const [password, setPassword] = useState<string>('')
  const [newPassword, setNewPassword] = useState<string>('')
  const [newPasswordConfirmation, setNewPasswordConfirmation] =
    useState<string>('')
  const [strongPassword, setStrongPassword] = useState<boolean>(true)

  const passwordsMatch = newPassword === newPasswordConfirmation
  const valid = password && newPassword && passwordsMatch && strongPassword

  const [changePassword, { loading }] = useMutation<
    { changePassword: { id: string } | null },
    { input: ChangePasswordInput }
  >(ChangePasswordMutation)

  const onSubmit = () => {
    changePassword({
      variables: {
        input: {
          password,
          newPassword,
        },
      },
    })
      .then(({ data, errors }) => {
        if (!errors && data?.changePassword?.id) {
          onClose()
          ToastNotifications.success('Your password has been updated.')
        } else {
          ToastNotifications.negative('We failed to update your password.')
        }
      })
      .catch(() => {
        ToastNotifications.negative('We failed to update your password.')
      })
  }

  return (
    <Modal dismissable onClose={onClose}>
      <ModalHeader>Change your password</ModalHeader>
      <ModalContent>
        <Section>Enter your old and new password.</Section>
        <Section>
          <FieldLabel label="Old Password">
            <FieldValue>
              <TextInput
                type="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                disabled={loading}
              />
            </FieldValue>
          </FieldLabel>
        </Section>
        <Section>
          <FieldLabel label="New Password">
            <FieldValue>
              <NewPasswordField
                value={newPassword}
                onChange={e => setNewPassword(e.target.value)}
                disabled={loading}
                onScore={(_, strong) => setStrongPassword(strong)}
                hasError={!!password && !strongPassword}
              />
            </FieldValue>
          </FieldLabel>
        </Section>
        <Section>
          <FieldLabel label="Confirm New password">
            <FieldValue>
              <TextInput
                type="password"
                value={newPasswordConfirmation}
                onChange={e => setNewPasswordConfirmation(e.target.value)}
                hasError={!passwordsMatch}
                disabled={loading}
              />
            </FieldValue>
          </FieldLabel>
        </Section>
      </ModalContent>
      <ModalActions>
        <Button disabled={!valid} loading={loading} onClick={onSubmit}>
          Update Password
        </Button>
      </ModalActions>
    </Modal>
  )
}
