import React, { forwardRef } from 'react'
import ReCAPTCHA, { ReCAPTCHAProps } from 'react-google-recaptcha'

const RECAPTCHA_DEV_SITE_KEY = '6Lf9XyIUAAAAAD-LDiWredOWYvcdbtYf_wYhWKiY'
const RECAPTCHA_PUBLIC_SITE_KEY = '6LfyFCIUAAAAAK7PmA78dUDFaoCfvPHRYclZgtH9'

// Having Recaptcha test key for cypress automated tests.
// https://developers.google.com/recaptcha/docs/faq#id-like-to-run-automated-tests-with-recaptcha.-what-should-i-do
const RECAPTCHA_TEST_SITE_KEY = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'

const recaptchaKey =
  document.location.hostname.indexOf('vendhq.com') !== -1 || document.location.hostname.indexOf('retail.lightspeed.app') !== -1
    ? RECAPTCHA_PUBLIC_SITE_KEY
    : 'Cypress' in window
    ? RECAPTCHA_TEST_SITE_KEY
    : RECAPTCHA_DEV_SITE_KEY

const recaptchaTheme: 'dark' | 'light' =
  window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
    ? 'dark'
    : 'light'

type StyledReCAPTCHAProps = Omit<
  ReCAPTCHAProps,
  'sitekey' | 'ref' | 'theme' | 'style' | 'size'
>

export const StyledReCAPTCHA = forwardRef<ReCAPTCHA, StyledReCAPTCHAProps>(
  (props, ref) => {
    return (
      <ReCAPTCHA
        sitekey={recaptchaKey}
        ref={ref}
        theme={recaptchaTheme}
        style={{ overflow: 'hidden', width: 302, height: 76 }}
        {...props}
      />
    )
  }
)
