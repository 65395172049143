import React from 'react'
import {
  ActionBar,
  ActionBarActions,
  ActionBarMessage,
  Header,
  Section,
  StickySection,
} from '@lightspeed/design-system-react'
import { ApplicationsTable } from '../components/ApplicationsTable/ApplicationsTable'
import { Link } from 'react-router-dom'

export const ApplicationsPage = () => {
  return (
    <>
      <Section>
        <Header level="1" type="grand">
          Applications
        </Header>
      </Section>
      <StickySection>
        <ActionBar variant="page">
          <ActionBarMessage>
            Add, view and edit applications all in one place.
          </ActionBarMessage>
          <ActionBarActions>
            <Link className="vd-btn vd-btn--go" to="/applications/add">
              Add Application
            </Link>
          </ActionBarActions>
        </ActionBar>
      </StickySection>
      <Section>
        <ApplicationsTable />
      </Section>
    </>
  )
}
