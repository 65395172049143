import { gql, useMutation, useQuery } from '@apollo/client'
import {
  ActionBar,
  ActionBarActions,
  ActionBarMessage,
  Button,
  Checkbox,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
  PageHeader,
  Section,
  SettingsSection,
  StickySection,
  TextInput, ToastNotifications,
} from '@lightspeed/design-system-react'
import { ChangeEventHandler, FunctionComponent, useState } from 'react'
import { Link } from 'react-router-dom'
import { ChangePasswordModal } from '../components/ChangePasswordModal/ChangePasswordModal'
import { EmailInput } from '../components/EmailInput/EmailInput'
import { onErrorToastNotifications } from '../util/client'
import {fetchWithCSRF} from "../util/csrf";
import { CountrySelect } from '@vend/business-components'

export const AccountFragment = gql`
  fragment AccountFragment on Developer {
    id
    email
    name
    organization
    city
    country
    phoneNumber
    wantsNewsletter
  }
`

const AccountQuery = gql`
  ${AccountFragment}

  query AccountQuery {
    developer {
      ...AccountFragment
    }
  }
`

const UpdateAccountMutation = gql`
  ${AccountFragment}

  mutation UpdateAccountMutation($input: UpdateAccountInput!) {
    updateAccount(input: $input) {
      ...AccountFragment
    }
  }
`

interface Account {
  id: string
  email: string
  name: string
  organization: string | null
  city: string | null
  country: string | null
  phoneNumber: string | null
  wantsNewsletter: boolean | null
}

interface UpdateAccountInput {
  name?: string
  email?: string
  phoneNumber?: string
  organization?: string
  city?: string
  country?: string
  wantsNewsletter?: boolean
}

export const AccountPage: FunctionComponent = () => {
  const [email, setEmail] = useState<string | undefined>(undefined)
  const [emailValid, setEmailValid] = useState<boolean>(true)
  const [name, setName] = useState<string | undefined>(undefined)
  const [organization, setOrganization] = useState<string | undefined>(
    undefined
  )
  const [city, setCity] = useState<string | undefined>(undefined)
  const [country, setCountry] = useState<string | undefined>(undefined)
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>(undefined)
  const [wantsNewsletter, setWantsNewsletter] = useState<boolean>()

  const onEmailChange: ChangeEventHandler<HTMLInputElement> = event => {
    setEmail(event.target.value)
    setEmailValid(!!event.target.value && event.target.checkValidity())
  }

  const [showChangePassword, setShowChangePassword] = useState<boolean>(false)
  const [showWarningAboutEmail, setShowWarningAboutEmail] =
    useState<boolean>(false)

  const { data, loading } = useQuery<{ developer: Account }>(AccountQuery)

  const [mutation, { loading: updating }] = useMutation<
    { developer: Account },
    { input: UpdateAccountInput }
  >(UpdateAccountMutation, { onError: onErrorToastNotifications })

  const updateAccount = () => {
    setShowWarningAboutEmail(false)
    return mutation({
      variables: {
        input: {
          name,
          email,
          phoneNumber,
          organization,
          city,
          country,
          wantsNewsletter,
        },
      },
    })
  }

  const onSubmit = () => {
    if (email && email !== data?.developer.email) {
      setShowWarningAboutEmail(true)
      return
    }

    updateAccount()
  }

  return (
    <>
      <form>
        <PageHeader>Update Your Account</PageHeader>
        <StickySection>
          <ActionBar variant="page">
            <ActionBarMessage>
              Update your contact information.
            </ActionBarMessage>
            <ActionBarActions>
              <Link className="vd-btn vd-btn--supplementary" to="/applications">
                Cancel
              </Link>
              <Button
                type="submit"
                disabled={
                  loading || updating || showWarningAboutEmail || !emailValid
                }
                loading={updating}
                onClick={onSubmit}
              >
                Save
              </Button>
            </ActionBarActions>
          </ActionBar>
        </StickySection>
        <Section>
          <SettingsSection header="Email">
            <SettingsSection.About>
              If you change your email address, you will have to verify your new
              address before you can log in again.
            </SettingsSection.About>
            <SettingsSection.Content>
              <EmailInput
                placeholder="Enter Email"
                value={email ?? data?.developer.email ?? ''}
                onChange={onEmailChange}
                disabled={loading || updating}
                hasError={!emailValid}
              />
            </SettingsSection.Content>
          </SettingsSection>
          <SettingsSection header="Name">
            <SettingsSection.About />
            <SettingsSection.Content>
              <TextInput
                placeholder="Enter Name"
                value={name ?? data?.developer.name ?? ''}
                onChange={e => setName(e.target.value)}
                disabled={loading || updating}
              />
            </SettingsSection.Content>
          </SettingsSection>
          <SettingsSection header="Password">
            <SettingsSection.About />
            <SettingsSection.Content>
              <Button onClick={() => setShowChangePassword(true)}>
                Change Password
              </Button>
            </SettingsSection.Content>
          </SettingsSection>
          <SettingsSection header="Organization">
            <SettingsSection.About />
            <SettingsSection.Content>
              <TextInput
                placeholder="Enter Organization"
                value={organization ?? data?.developer.organization ?? ''}
                onChange={e => setOrganization(e.target.value)}
                disabled={loading || updating}
              />
            </SettingsSection.Content>
          </SettingsSection>
          <SettingsSection header="City">
            <SettingsSection.About />
            <SettingsSection.Content>
              <TextInput
                placeholder="Enter City"
                value={city ?? data?.developer.city ?? ''}
                onChange={e => setCity(e.target.value)}
                disabled={loading || updating}
              />
            </SettingsSection.Content>
          </SettingsSection>
          <SettingsSection header="Country">
            <SettingsSection.About />
            <SettingsSection.Content>
              <CountrySelect
                placeholder="Enter Country"
                value={country ?? data?.developer.country ?? ''}
                onChange={e => setCountry(e.target.value)}
                disabled={loading || updating}
              />
            </SettingsSection.Content>
          </SettingsSection>
          <SettingsSection header="Phone Number">
            <SettingsSection.About />
            <SettingsSection.Content>
              <TextInput
                type="tel"
                placeholder="Enter Phone Number"
                value={phoneNumber ?? data?.developer.phoneNumber ?? ''}
                onChange={e => setPhoneNumber(e.target.value)}
                disabled={loading || updating}
              />
            </SettingsSection.Content>
          </SettingsSection>
        </Section>
        <Section>
          <Checkbox
            label="I'd like to receive Lightspeed Retail's Developer Update newsletter"
            checked={
              wantsNewsletter ?? (data?.developer.wantsNewsletter || false)
            }
            onChange={e => setWantsNewsletter(e.target.checked)}
            disabled={loading || updating}
          />
        </Section>
      </form>
      {showChangePassword && (
        <ChangePasswordModal onClose={() => setShowChangePassword(false)} />
      )}
      {showWarningAboutEmail && (
        <Modal focusTrapActive={false}>
          <ModalHeader>Changing your email address</ModalHeader>
          <ModalContent>
            <p>
              Since you are changing your email address, we will send you an
              email to {email}.
            </p>
            <p>
              You will be logged out and need to click the link in this email to
              verify your address before you can log back in.
            </p>
            <p>Are you sure you want to change your email address?</p>
          </ModalContent>
          <ModalActions className="vd-btn-group">
            <Button
              disabled={loading || updating}
              variant="supplementary"
              onClick={() => setShowWarningAboutEmail(false)}
            >
              No
            </Button>
            <Button
              disabled={loading || updating}
              variant="no"
              onClick={() =>
                updateAccount().then(({ errors }) => {
                  if (errors) return

                  fetchWithCSRF('/email_changed', {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ "id": data?.developer.id, "email": data?.developer.email }),
                  }).then(response => {
                    if (!response.ok) {
                      ToastNotifications.negative('Something went wrong')
                    }
                  })

                  document.location.assign(
                    '/logout?success=' +
                      encodeURIComponent(
                        "We've sent you an email. Please follow the directions in the email to activate your account."
                      )
                  )
                })
              }
            >
              Yes
            </Button>
          </ModalActions>
        </Modal>
      )}
    </>
  )
}
