import { Icon } from '@lightspeed/design-system-react'
import React from 'react'
import { Link, Route, Routes, useLocation } from 'react-router-dom'

import './SideNav.scss'

const navItems = [
  {
    key: 'home',
    icon: 'home',
    url: '/applications',
    label: 'Home',
  },
  {
    key: 'setup',
    icon: 'setup',
    vendIcon: true,
    url: '/account',
    label: 'Account',
  },
]

const Nav = () => {
  let { pathname } = useLocation()

  return (
    <div className="vd-sidebar">
      <div className="vd-sidebar-content">
        <div className="vd-sidebar-tabs vd-sidebar-tabs--expanded">
          {navItems.map(item => (
            <div
              className={
                pathname.startsWith(item.url)
                  ? 'vd-nav-item vd-nav-item--active'
                  : 'vd-nav-item'
              }
              key={item.key}
            >
              <Link className="vd-nav-item-action" to={item.url}>
                <Icon
                  vend={item.vendIcon}
                  icon={item.icon}
                  className="vd-nav-item-icon"
                />
                <div className="vd-nav-item-label">{item.label}</div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

const NoNav = () => null

export const SideNav = () => {
  return (
    <Routes>
      <Route path="/login" element={<NoNav />} />
      <Route path="/forgot-password" element={<NoNav />} />
      <Route path="/reset-password/:token" element={<NoNav />} />
      <Route path="/register" element={<NoNav />} />
      <Route path="/" element={<NoNav />} />
      <Route path="*" element={<Nav />} />
    </Routes>
  )
}
