import {
  Button,
  FieldLabel,
  FieldValue,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
  Section,
  TextInput,
  ToastNotifications,
} from '@lightspeed/design-system-react'
import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { fetchWithCSRF } from '../util/csrf'
import ReCAPTCHA from 'react-google-recaptcha'
import { StyledReCAPTCHA } from '../util/recaptcha'

export const LoginPage = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>()
  const [params] = useSearchParams()

  const recaptchaRef = useRef<ReCAPTCHA>(null)

  useEffect(() => {
    const success = params.get('success')
    if (success) {
      setTimeout(() => ToastNotifications.success(success), 100)
    }

    const fail = params.get('fail')
    if (fail) {
      setTimeout(() => ToastNotifications.negative(fail), 100)
    }
  }, [params])

  const onSubmit = async () => {
    fetchWithCSRF('/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password, recaptchaToken }),
    })
      .then(response => {
        if (response.ok) {
          const returnTo = params.get('return_to')
          navigate(returnTo || '/applications', { replace: true })
        } else if (response.status === 401) {
          ToastNotifications.negative('Wrong email/password')
        } else {
          ToastNotifications.negative('Something went wrong')
        }
      })
      .finally(() => {
        if (recaptchaRef.current?.reset) {
          recaptchaRef.current?.reset()
        }
      })
  }

  return (
    <Modal focusTrapActive={false}>
      <ModalHeader>Sign in</ModalHeader>
      <ModalContent>
        <Section>
          <FieldLabel label="Email" />
          <FieldValue>
            <TextInput
              type="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </FieldValue>
        </Section>
        <Section>
          <FieldLabel label="Password" />
          <FieldValue>
            <TextInput
              type="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </FieldValue>
        </Section>
        <Section>
          <StyledReCAPTCHA onChange={setRecaptchaToken} ref={recaptchaRef} />
        </Section>
      </ModalContent>
      <ModalActions>
        <div className="vd-flex vd-flex--row vd-flex vd-flex--justify-between">
          <Link
            to="/forgot-password"
            style={{ padding: '0 var(--vd-section-padding-h)' }}
            className="vd-link vd-link--secondary"
          >
            Forgot your password?
          </Link>
          <div className="vd-align-right">
            <Button
              onClick={onSubmit}
              disabled={!email || !password || !recaptchaToken}
            >
              Sign in
            </Button>
          </div>
        </div>
      </ModalActions>
    </Modal>
  )
}
